.Paper {
    padding: 30px 30px;
}

.EndpointsBox {
    overflow-y: scroll;
    height: 350px;
    margin-top: 5px;
}

.JoinColumnBox {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    margin-top: 16px;
}

.EndpointBox {
    margin: 16px 24px
}

.EndpointsPage {
    height: 600px
}

.EndpointAddBtn {
    text-align: center;
    padding: 24px 0;
}
