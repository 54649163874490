@import "../../../index.scss";

.complianceStatus {
  border-radius: 5px;
  padding: 10px;
  color: white;
}

.complianceStatusWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;

  p {
    margin: 0 0 0 10px;
  }
}

.progressTemplates {
  width: 15%;
  margin-right: 8px;
  display: inline-block;
}

button {
  &.printListing {
    @include unStyledButton;
  }

  .printIcon {
    margin-right: 10px;
  }

  .angleIcon {
    padding-left: 8px;
  }
}

.templatePreviewHead {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  padding-bottom: 4px;
}

.criteriaDesc {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.54);
}

.criteriaName {
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #000000 !important;
}

.showMore {
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 150% !important;
  letter-spacing: 0.15px !important;
  text-decoration-line: underline !important;
  text-transform: none !important;
  color: #000000 !important;
}

.screenshotText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  padding-bottom: 10px;
}

.ownerInfoDetail {
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 26px !important;
  color: #000000 !important;
  padding-top: 4px !important;
}

.ownerInfoLabel {
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #000000 !important;
}

.summaryModal {
  .modalBox {
    width: 60% !important;

    .modalContentOuter {
      max-height: 90vh !important;
    }
  }

  .modalContentOuter>.modalContentInner {
    height: 90vh;
  }
}

.preview {
  padding-left: 16px;
  border-top: 1px solid #D8D8D8;
  padding-top: 6px;
  height: 60px;

  .subText {
    display: block;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    font-size: 10px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #000000;
  }

  .generatePreview {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */

    letter-spacing: 0.15px;

    color: rgba(0, 0, 0, 0.5);
  }

  .genPreview {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.15px;
    text-decoration-line: underline;
    text-transform: none !important;
    color: #000000;
  }

  .openPreview {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 143%;
    letter-spacing: 0.15px;
    color: #000000;
    text-transform: none !important;

  }
}