.buttonContainer {
    display: flex;
    justify-content: flex-end;
    padding: 25px;
    gap: 10px;

    .submitButton {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.4000000059604645px;
        text-align: left;
    }
}

.confirmationMainDiv {
    padding-left: 4rem;
    padding-top: 2.5rem;

    .doneIcon {
        .MuiSvgIcon-fontSizeLarge {
            color: #0000008A;
            font-size: 6rem;
        }

    }

    .ready {
        font-family: Roboto;
        font-size: 24px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
    }

    .confirmationText {
        padding-top: 2rem;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 300;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: left;

    }

    .confirmationAddress {
        padding-top: 0.5rem;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;

    }
}

.confirmation {
    .modalBox {
        width: 38% !important;
        min-width: 440px !important;
        min-height: 500px !important;
        height: 50% !important;
    }
}

.onWay {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    align-items: center;
    display: flex;

}

.letterPrepared {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 300;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
}

.dropdownWithEllipsis {
    width: 140px !important;
    height: 2rem !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    border: none !important;
    outline: none !important;

    .MuiOutlinedInput-notchedOutline {
        border-bottom: 1px solid #000000DE;
        border-radius: 0px !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
    }
}

.react-swipeable-view-container>div {
    overflow: hidden !important;
}

.supportSection {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0em;
    text-align: left;
}