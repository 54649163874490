.galleryBox {
  position: relative;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  ul {
    border-radius: 10px;
    margin: 0;
  }
  Button {
    &.showAllPhotos {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px;
      position: absolute;
      width: 88px;
      height: 36px;
      background: #FFFFFF;
      border: 0.5px solid #000000;
      border-radius: 4px;
      bottom: 15px;
      right: 15px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #000000;
      cursor: pointer;
    }
  }
}
