.addressInformationWrapper {
  background-color: #fff;
  padding: 15px 10px;
  border-radius: 10px;
  // min-height: 95%;
  // max-height: 95.25% !important;
  width: 100%;
}

.iconWrapper {
  padding-top: 5px;
  & > * {
    margin-left: 30px;
  }
}

.MuiTypography-h6 {
  margin-top: 10px;
  &.bold {
    margin-top: 0;
    font-weight: 700;
  }
}

.informationContainer {
  & > * {
    margin-bottom: 30px;
  }
  .rentalUnitMap {
    margin-top: 50px;
  }
}

.rentalUnitMap {
  margin-bottom: 0;
}

.map {
  width: 95%;
  margin: 0 auto;
}

.gray {
  color: #747474;
}

.hoverClass {
  &:hover {
    cursor: pointer;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow-wrap: anywhere;
}

.informationTile {
  padding: 1% 3%;
}

.ownerInfo {
  margin-left: -2% !important;
}

.infoGrey {
  border-radius: 10px;
  &:hover {
    background: #f1f1f1;
  }
}

.MuiGrid-grid-xs-1.informationTile {
  padding-top: 10px;
}
