.change-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px 50px;
}

.change-password-header{
  border-bottom: 1px solid #d3d3d3;
  padding: 15px;
}
