.templateTitle {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin: 2%;
}

.eligibilityMsg {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    margin: 2%;
    display: flex;
}

.starIcon {
    margin-right: 2%;
    margin-left: 1%;
    color: gray;
}

button {
    &.backButton {
        text-transform: none;
        font-weight: 500;
        color: #000000;
    }

    &.openPreview {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        text-transform: none !important;
    }

    &.genPreview {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-decoration-line: underline;
        text-transform: none;
        color: #000000;
    }
}

.goBackSection {
    font-family: Roboto;
    margin: 2%;
    margin-bottom: 0%;
}

.instructionSection {
    font-family: Roboto;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
}

.reviewRecipientsContainer{
    height:100%;
    padding:16px;

    .sampleThumbnailContainer{
        padding:0 16px;
        
        .sampleTemplateText{
            margin-bottom:16px
        }
        .thumbnailBox{
            border:1px solid rgb(0, 0, 0);
            margin-bottom:2vh
        }
        .recipientsCriteriaList{
            height:30vh;
            overflow-y:auto;

            .recipientsCriteriaListItem{
                align-items: flex-start;
                padding:5px 0;
                margin-bottom: 10px;

                .recipientsCriteriaListItemIcon{
                    min-width:20%;

                    svg{
                        font-size:1rem;
                        margin-top:5px
                    }
                }
            }
        }
    }
}

.hoverCell {
    &:hover {
      position: absolute;
      z-index: 10000;
      background-color: #f5f5f5;
      padding: 5px;
    }
    line-height: 250%;
}

.dark-row-hover .MuiDataGrid-row:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
}