@import "../../../../../index.scss";

.modalContentOuter > .modalContentInner {
    padding: 0;
    height: 100%;
    .screenshotModalBox {
        width: 100%;
        height: 80vh;
        .innerDiv {
            display: flex;
            flex-direction: row;
            aside.aside {
                background: #fff;
                width: 320px;
                height: 100%;
                .asideContainer {
                    width: 320px;
                    height: 360px;
                    .select {
                        position: fixed;
                        top: 80px;
                        left: 0;
                        width: 300px;
                    }
                    .calendar>div>div>div {
                        margin: 0;
                    }
                    .calendar {
                        position: fixed;
                        top: 150px;
                        left: 0;
                        .MuiPickersDay-root {
                            background-color: rgba(16, 50, 85, 0.3);
                            color: #fff;
                        }
                        button.Mui-disabled {
                            background-color: #fff;
                            color: rgba(0, 0, 0, 0.38); 
                        }
                        button.Mui-selected {
                            background-color: #003258;
                        }
                    }
                }
            }
            .main {
                position: relative;
                background-color: #f0f0f0;
                padding: 20px;
                padding-top: 0;
                width: 100%;
                height: 100%;
                .screenshotInformation {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    justify-content: space-between;
                    width: 100%;
                    .screenshotDate {
                        background-color: #e0e0e0;
                        height: 40px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 0 10px;
                        width: fit-content;
                        .date {
                            white-space: nowrap;
                        }
                    }
                    .buttonGroup {
                        width: inherit;
                        display: inline-flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        margin: 10px 0;
                        .topButton {
                            @include unStyledButton;
                            margin-left: 20px;
                        }
                    }
                }
                .screenshotWrapper {
                    .spinnerContainer {
                        height: 73vh;
                        span {
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
