.flex-row-letters {
    align-items: flex-start;
}

.flex-element-letters {
    width: 45% !important;
}

.flex-letters-group {
    width: 100%;
}

.chevron-letters {
    margin-top: 6px;
}