.MuiDrawer-paperAnchorLeft {
  background: linear-gradient(0deg, rgba(118, 126, 224, 0.1) 1%, #eee);
}

.hamburger-menu {
  margin-top: 3px !important;
  &:hover {
    background-color: #fdfdfd !important;
  }
}

.sidemenu {
  min-width: 300px;
  max-width: 300px;
  margin-right: 8px;
  overflow-wrap: break-word;
  img {
    margin: 14px 0 0 14px;
  }
  nav {
    .MuiCollapse-root {
      padding-bottom: 14px;
      .MuiButtonBase-root {
        padding: 0 0 0 0;
        border-left: 4px solid transparent;
        &.active {
          background-color: white;
          border-left-color: #003258;
          border-top-right-radius: 40px;
          border-bottom-right-radius: 40px;
        }
        .MuiListItemText-inset {
          padding-left: 38px;
        }
      }
    }
    .MuiListItem-gutters {
      padding-left: 10px;
      padding-right: 10px;
    }
    .MuiButtonBase-root {
      &:hover {
        background-color: inherit;
        text-decoration: underline;
      }
      .MuiListItemIcon-root {
        min-width: 24px;
        margin-right: 6px;
        svg {
          color: #003258;
          margin: 0 auto;
        }
      }
      .MuiListItemText-root {
        span {
          font-weight: 300;
          small {
            font-size: 70%;
            color: #003258;
            text-transform: uppercase;
            position: absolute;
            right: 8px;
            top: 22%;
          }
        }
      }
    }
    section > {
      :not(.MuiCollapse-wrapper) {
        &.MuiButtonBase-root {
          .MuiListItemText-root span {
            font-weight: 600;
          }
        }
      }
      .MuiButtonBase-root.Mui-disabled {
        opacity: 1;
      }
    }
  }
}
