.jurisdictions {
  .jurisdictions-search {
    padding-left: 8px !important;
  }

  .MuiListItem-container {
    &:hover {
      background: #0000000f;
    }
  }

  .change-button,
  .current-jurisdiction {
    color: #003258;
  }
}
