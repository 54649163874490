.listingInformationContainer {
    background-color: #fff;
    padding: 15px 25px;
    border-radius: 10px;
    flex-grow: 1;
    max-width: 95%;
    .title.bold {
        margin-top: -5px;
        margin-bottom: 20px;
    }
    .tabList {
        .tabLabel {
            font-size: 16px;
            height: 29px;
            left: 17px;
            color: rgba(0,0,0,0.25);
            &.Mui-selected {
                background: #F1F1F1;
                border-radius: 10px;
                color: #103255;
            }
            .icon {
                font-size: 0.8rem;
            }
            .subsiteIcon {
                height: 16px;
            }
            .labelText {
                font-size: 1.05rem;
                margin: 0 10px;
            }
            p {
                &.MuiTypography-root {
                    display: flex;
                    span {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
        .MuiTabs-indicator {
            background-color: transparent;
        }
    }
    .buttonBox {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        .MuiButton-root {
            margin: 0 10px;
        }
    }
    .table {
        .MuiTableRow-root{
            &:nth-last-of-type(1) {
                .MuiTableCell-root {
                    border-bottom: none;
                }
            }
            .MuiTableCell-root {
                padding: 13px 18px;
                border-bottom: 1px solid rgb(0 0 0 / 10%);
            }
        }
    }
    .MuiPaper-root {
        box-shadow: none;
    }
    .bold {
        font-weight: 700;
    }
    .MuiBox-root {
        border-bottom: none;
    }
}
