$side-controls-width: 50px;

.galleryModalBox {
  .bigImage {
    position: relative;
    height: 50%;
    text-align: center;
    .prevImage {
      height: 100%;
      background: white;
      position: absolute;
      opacity: 0.5;
      width: $side-controls-width;
      .innerPrevImage {
        position: relative;
        width: 100%;
        height: 100%;
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
        }
      }
    }
    .nextImage {
      height: 100%;
      background: white;
      position: absolute;
      opacity: 0.5;
      width: $side-controls-width;
      right: 0;
      top: 0;
      .innerNextImage {
        position: relative;
        width: 100%;
        height: 100%;
        svg {
          position: absolute;
          top: 50%;
          right: 50%;
        }
      }
    }
  }
  .closeButton {
    float: right;
    margin-top: -25px;
    margin-right: -25px;
  }
  .imageList {
    margin: 0;
    height: 100%;
    .selected {
      outline: 3px solid deepskyblue;
      outline-offset: -3px;
    }
  }
}
