.matchDetails {
  padding: 12px 26px;
  h6 {
    &.MuiTypography-h6 {
      &.bold {
        &:not(:first-child) {
          margin-top: 15px;
        }
      }
    }
  }
  .matchPhotosList {
    margin-top: 15px;
  }
}
