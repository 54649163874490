.printTemplateBox {
    display: none;

    padding: 20px;
    .screenshotInformation {
        margin: 10px;
    }
    img {
        width: 100%;
    }
    .bold {
        font-weight: bold;
    }
}

@media print {
    .printTemplateBox {
        display: inline-block;
    }
}