@media print {
  .hiddenPrint {
    display: none !important;
  }

  .tabList {
    position: relative;
    left: -28px;
  }

  div {
    .MuiGrid-item {
      page-break-inside: avoid;
    }
  }

  html,
  body {
    height: 99% !important;
    font-size: 14px;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .print {
    line-height: 1 !important;
  }

  .print:last-child {
    page-break-after: avoid;
  }
}

@page {
  margin: 8mm 15mm 8mm 8mm;
  width: 100% !important;
}

.gallery {
  padding-top: 16px;
  padding-left: 16px;
}

.listingInfo {
  max-width: 66%;
  width: 66%;
}

.di-flex {
  display: flex;
  width: calc(100% + 16px);
}

.table {
  width: 100%;
}

.activityTimelineDiv {
  table {
    border-spacing: 0px;
    text-align: left;
  }

  td,
  th {
    padding: 14px 15px;
    margin: 0;
  }

  tr td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  tr th {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
}

.activityTimelineDiv {
  background-color: #fff;
  padding: 20px 30px 24px 30px;
  margin-bottom: 30px;
  height: fit-content;
  border-radius: 10px;
  page-break-before: always;
  width: 1366px;

  .bold {
    font-weight: 700;
  }

  .title {
    margin-bottom: 20px;
  }

  .activityTimeline {
    margin: 10px 20px;
  }
}
.footer {
  position: absolute;
  right: 0;
  padding-top: 10px 
}