.RegistrationsView .HTMLCell {
  line-height: 250%;
}

.RegistrationsView .property_pages_cell {
  line-height: 250%;
}

.UploadcareCDNUrlCell {
  line-height: 250%;
}