.stepCounter {
  margin: 30px 0 10px;
}

.navButton {
  margin-left: 20px !important;
}

.errorMessage {
  color: #f44336;
  padding-right: 5px;
}