.gridContainer {
  border-bottom: #f0f0f0 solid 2px;
  margin: 10px 0;

  .image {
    box-sizing: border-box;
    width: 95%;
    margin: 5px;
  }

  .comment {
    text-align: center;
  }

  .gridItem {
    background-color: #f0f0f0;
    box-sizing: border-box;
    margin: 0;
    flex: 0 0 45%;
    max-width: 45%;
    flex-direction: row;
  }

  .gridItem2 {
    box-sizing: border-box;
    margin: 0;
    flex-direction: row;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .grid-item-container {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    flex-direction: row;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .equalsToSign {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 10%;
    max-width: 10%;
  }
}