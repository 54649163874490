.errorWarning {
    color: #f44336;
    padding: 0px 15px;
}

.custom-accordion {
    &-left {
        flex:1;
    }

    &-right {
        display: flex;
        align-items: center;
    }
}


