.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 0;
  border-radius: 5px;
  .title {
    border-bottom: 1px lightgray solid;
    padding: 15px;
  }
  .closeButton {
    float: right;
    background: white;
    padding: 5px 15px;
    color: grey;
    cursor: pointer;
    margin: 0;
    border-radius: 5px;
    &:hover {
      background: #f1f1f1;
    }
  }
  .modalContentOuter {
    position: relative;
    max-height: 80vh;
    overflow: auto;
  }
  .modalContentInner {
    position: relative;
    padding: 15px;
  }
}
