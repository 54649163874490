.letterDataBox {
    margin-top: 30px;
}

.grid-container {

    .grid-item-xs-12 {
        box-sizing: border-box;
        margin: 0;
        flex-direction: row;
        flex-basis: 200%;
        flex-grow: 0;
        max-width: 200%;
    }
        
    .grid-item-xs-1 {
        box-sizing: border-box;
        margin: 0;
        flex-direction: row;
        flex-basis: 16.666667%;
        flex-grow: 0;
        max-width: 16.666667%;
    }

    .grid-item-xs-5 {
        box-sizing: border-box;
        margin: 0;
        flex-direction: row;
        flex-basis: 83.333333%;
        flex-grow: 0;
        max-width: 83.333333%;
    }
}


