.AppBar {
  box-shadow: none !important;
  background-color: #fdfdfd !important;
}

.AppBarIcon {
  max-height: 26px;
  margin-left: 10px;
}

.ArrowIcon {
  padding: 0 5px 1px 5px;
  height: 0.8em;
}

.HamburgerMenu {
  margin-top: 3px !important;
  &:hover {
    background-color: #fdfdfd !important;
  }
}
