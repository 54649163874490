.dropdownWithEllipsis {
    width: 140px !important;
    height: 2rem !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    border: none !important;
    outline: none !important;

    .MuiOutlinedInput-notchedOutline {
        border-bottom: 1px solid #000000DE;
        border-radius: 0px !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
    }
}
