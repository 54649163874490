.modal {
  .MuiDialogContent-root {
    overflow: visible;
  }
  .SectionNames {
    font-size: 20px;
    font-weight: bold;

    .MailingAddressTitle {
      margin-left: 15px;
    }
  }

  .MainLabel {
    font-size: 16px;
    font-weight: bold;
    color: black;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .SuccessButton {
    width: 100px;
    height: 40px;
    margin-right: 15px;
    margin-bottom: 20px;
    border: none;
    border-radius: 4px;
  }

  .ErrorMessage {
    font-size: 14px;
    color: red;
    margin-top: 20px;
  }

  .ownerFlow{
    .stepCounterEditOwnerAddress {
        margin: 10px -8% 30px;
      }
    .editOwnerText {
        padding-bottom: 16px;
    }
    .editOwnerFooterText{
        font-style: italic;
        opacity: 60%;
        text-align: center;
        padding-top: 16px;
    }
    .editOwnerButtons{
        display: flex;
        justify-content: flex-end;
        margin-top: 50px;
    }
    .lettersDataContainer{
        max-height: 45vh;
        overflow-x: hidden;
        overflow-y: auto;
        .lettersDataBox{
            width: 100%; 
            margin-bottom: 15px;
            .letterInputLabel{
                padding-left:2%
            }
        }
    }
  }
}
