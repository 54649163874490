@import "../../../../../index.scss";

.modalContentOuter {
    overflow: hidden;

    &::-webkit-scrollbar {
        display: none;
    }

    max-height: 90vh !important;
}

.modalContentOuter>.modalContentInner {
    padding: 0;
    height: 100%;
    overflow: visible;

    .letterModalBox {
        width: 100%;
        height: 100%;

        &::-webkit-scrollbar {
            display: none;
        }

        .innerDiv {
            display: flex;
            flex-direction: row;

            aside.aside {
                background: #fff;
                width: 360px;
                height: 80vh;

                .asideContainer {
                    width: 360px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;

                    .buttonsContainer {
                        overflow-y: scroll;
                        height: 100%;
                        width: 100%;
                        padding: 0;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        .buttonElement {
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            border: none;
                            padding: 14px;
                            box-shadow: none;
                            border-radius: none;
                            justify-content: space-between;
                            border-radius: 0;

                            .buttonInfo {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                text-align: left;

                                .buttonHeadline {
                                    font-weight: 500;
                                    font-size: 16px;
                                    color: #103255;
                                    text-transform: capitalize;
                                }

                                .buttonDateTime {
                                    font-weight: 300;
                                    font-size: 14px;
                                    color: #49454F;
                                    text-transform: capitalize;
                                }

                                p {
                                    display: block;
                                }

                            }

                            .MuiTouchRipple-root {
                                display: none !important;

                                .MuiTouchRipple-rippleVisible {
                                    display: none !important;
                                }
                            }

                            .buttonStatus {
                                font-weight: 400;
                                font-size: 11px;
                                color: #103255;
                                text-transform: capitalize;
                            }

                            p {
                                display: block;
                            }
                        }

                        .selectedLetter {
                            &:hover {
                                cursor: default;
                                background-color: #103255;
                            }

                            .buttonInfo {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                text-align: left;

                                .buttonHeadline {
                                    font-weight: 400;
                                    font-size: 16px;
                                    color: #fff;
                                    text-transform: capitalize;
                                }

                                .buttonDateTime {
                                    font-weight: 400;
                                    font-size: 14px;
                                    color: #fff;
                                    text-transform: capitalize;
                                }

                                p {
                                    display: block;
                                }
                            }

                            .buttonStatus {
                                font-weight: 400;
                                font-size: 11px;
                                color: #fff;
                                text-transform: capitalize;
                            }

                            p {
                                display: block;
                            }
                        }

                        .hoverClass {
                            background-color: #f0f0f0;
                        }

                        .buttonShowMore {
                            text-decoration: underline;
                            margin: 5px 15px;
                            cursor: pointer;

                            &:hover {
                                color: #103255;
                            }
                        }

                        .showMoreLoading {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            margin: 5px 15px;

                            .circularProgress {
                                display: block;
                                height: 10px;
                            }
                        }
                    }
                }
            }

            .main {
                position: relative;
                background-color: #f0f0f0;
                padding: 0 40px;
                width: 100%;
                // height: 100%;
                overflow: scroll;

                &::-webkit-scrollbar {
                    display: none;
                }

                .letterInformation {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    justify-content: space-between;
                    width: 100%;

                    .letterDate {
                        background-color: #e0e0e0;
                        height: 40px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 0 10px;
                        width: fit-content;

                        .date {
                            white-space: nowrap;
                        }
                    }

                    .buttonGroup {
                        width: inherit;
                        display: inline-flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        margin: 10px 0;

                        .topButton {
                            @include unStyledButton;
                            margin-left: 20px;
                        }
                    }
                }

                .letterWrapper {
                    // height: 100%;
                    width: 100%;
                    overflow-y: scroll;
                    overflow-x: hidden;
                    scrollbar-color: #103255 #f0f0f0;
                    position: relative;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .letter {
                        width: 100%;

                        .react-pdf__Page {
                            width: 100%;
                            height: 100%;

                            canvas.react-pdf__Page__canvas {
                                width: 100% !important;
                                height: 100% !important;
                            }
                        }

                        .react-pdf__Page__textContent {
                            width: 100% !important;
                            height: 100% !important;
                        }

                        .react-pdf__Page__annotations {
                            height: 0;
                        }

                        .react-pdf__message {
                            height: 100%;
                            margin: calc(41vh - 33.50px) auto;
                            text-align: center;
                        }
                    }

                    .buttonGroup {
                        position: absolute;
                        width: 100%;
                        bottom: 40px;
                        box-sizing: border-box;
                        padding: 0 10px;
                    }

                    .spinnerContainer {
                        height: 73vh;

                        span {
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }

                    img {
                        width: 100%;
                    }
                }

                .pageSwitcher {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                }

                .loadingContainer {
                    height: 80vh;
                }
            }
        }
    }
}

.spinnerContainer {
    height: 73vh;

    span {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}