.LeftSection {
  width: 40%;
  background-color: #f0f0f0 !important;
}

.RightSection {
  display: flex;
  justify-content: center;
  width: 60%;
  background-color: #ffffff !important;
}

.HcIcon {
  max-height: 26px;
  margin-left: 20px;
  margin-top: 20px;
}

.LeftSideDescription {
  margin: 60px 70px 70px 40px !important;
}

.EsriPage {
  width: 100%;
  height: 100%;
  display: flex;
}
