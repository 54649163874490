
.questionnaires header {
  background-color: #fafafa;
  border-bottom: 1px solid #d2d2d2;
}

.questionnaires header a.logo {
  margin: 4px;
}

.questionnaires header a.logo img {
  max-height: 87px;
  height: 100%;
}

.questionnaires footer {
  font-size: 80%;
  font-weight: 400;
  text-align: center;
}

.questionnaires footer p {
  font-size: 120%;
  margin-top: 0;
  padding: 16px;
}

.questionnaires section {
  background-color: #eeeeee;
  padding-bottom: 1px;
}

.questionnaires h1 {
  background-color: #fff;
  color: rgb(255, 82, 64);
  font-size: 32px;
  font-weight: 400;
  margin: 0;
  padding: 24px 48px;
}

.qjson {
  background-color: #fff;
  margin: 48px;
  padding: 48px;
}

.dashboardQjson {
  background-color: #fff;
  margin: 20px;
  padding: 30px;
}
