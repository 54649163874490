@import "../../index.scss";

.search-bar {
  margin: "10px 0";
  justify-content: "flex-start";

  .search-container {
    background-color: white;
    padding-left: 36px;
    margin: 0px 8px;
    border: 1px solid lightgray;
    border-radius: 4px;
  }

  button {
    margin: 1px 0px 0px -32px;
    position: "absolute";
    pointer-events: none;
    font-size: 16px !important;
  }

  input {
    height: 2em;
  }
}

.sendLetter {
  position: absolute;
  right: 35px;

  button {
    pointer-events: auto !important;
  }
}

.hideSendLetter {
  position: absolute;
  right: 220px;

  button {
    pointer-events: auto !important;
  }
}

button {
  &.sendLetterTemplates {
    height: 40px !important;
    pointer-events: auto !important;
    text-transform: none;

    &:hover {
      background-color: #103255;
    }
  }

  .angleIcon {
    padding-left: 8px;
  }
  
  &.clearSelectionButton {
    float: right;
    text-transform: none;
    font-weight: 400;
    color: #000000;
    text-decoration: underline;
  }
}

.progressSpin {
  padding-right: 8px;
  padding-top: 8px;
  display: table-cell;
}