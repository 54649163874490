html,
body,
#root,
#root > div {
  height: 100%;
}

body {
  background-color: #f0f0f0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 110%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiCard-root {
  margin-bottom: 10px;
}

.warn,
a {
  color: #003258;
}

.d-flex {
  display: flex;
  border-bottom: 0.2px solid lightgray;
}

.pt-8 {
  padding: 12px 10px 4px 10px;
}

.padding-top {
  padding-top: 8px;
}

@mixin unStyledButton {
  width: fit-content;
  justify-content: center;
  align-items: center;
  height: 30px;
  border: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-decoration-line: underline;
  color: #000000;
  cursor: pointer;
  display: inline-flex;
  &:hover {
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
  }
}


.esri-popup, .esri-popup__main-container {
  border-radius: 12px !important;
}

.mapInfoContent {
  font-family: 'Roboto';
  color: rgba(0, 0, 0, 0.87);
}

.filterPanel {
  display: inline-flex;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  right:0;
}

.applyFilter {
  color:  #003258 !important;
}

@media print {
  .hiddenPrint {
    display: none !important;
  }





  div {
   
    .MuiGrid-item{
      page-break-inside: avoid;
    }
    .rentalUnitMap {
      max-width: 60% !important;
      page-break-inside: avoid;
      overflow: hidden !important;
    }
   
   
 
  }

  html, body {
    height: 99% !important;
    font-size: 14px;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
 }

 .print {
  line-height: 1 !important;
}
.print:last-child {
  page-break-after: avoid;
}
}