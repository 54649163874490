.extendedDropZone {
  background: rgba(0, 0, 0, 0.05);
  height: 35%;
  width: 35%;
  position: fixed;
  padding: 0 !important;
  margin: 10px;
  border: 2px dashed #003258;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 300;
  font-family: "Roboto";
  color: #000000;
}

.comments {
  display: inline-flex;
  color: rgba(0, 0, 0, 0.54);
}

.commentsList {
  overflow-y: auto;
  .commentsListItem{
    align-items: flex-start;
    .commentUserAvatar{
      padding-top: 4px;
    }
  }
  .commentsBody {
    font-family: "Roboto";
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5;
    padding-top: 4px;
    color: #000000;
    white-space: break-spaces;
  }
  .commentsAuthor {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
  .commentsTime {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    padding-top: 8px;
  }
  .commentsFile {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    padding-top: 12px;
  }
}

.commentsInput {
  width: 100%;
  height: 165px !important;
  left: 33px;
  top: 96px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  resize: none;
  border: none;
  padding: 8px;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5;
  font-family: "Roboto";
  font-style: normal;
  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
    font-size: 14px;
  }
  &:focus-visible {
    outline-color: #003258;
  }
}

.dropzone {
  text-align: center;
  width: 40%;
  height: 42px;
  margin-bottom: 20px;
  font-family: "Roboto";
  border: 0.5px dashed rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  font-weight: 300;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.3);
  display: table-cell;
}

.dropzone:hover {
  background: rgba(0, 0, 0, 0.05);
}

.dragFile {
  text-align: center;
  width: 40%;
  height: 42px;
  margin-bottom: 20px;
  font-family: "Roboto";
  border: 0.5px dashed #000000;
  border-radius: 6px;
  font-weight: 300;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.3);
  background: rgba(0, 0, 0, 0.05);
  display: table-cell;
}

.commentsDrawerHeader {
  font-weight: 700;
  font-size: 24px;
  line-height: 157%;
  letter-spacing: 0.1px;
  color: #000000;
}

.d-in-flex {
  display: inline-flex;
}

.commentsSource {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);
  padding-left: 4px;
}

.pd-4 {
  padding: 4px;
}

.dragComponent {
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.closeButton {
  float: right;
  background: white;
  padding: 6px 16px;
  color: grey;
  cursor: pointer;
  margin: 0;
  border-radius: 5px;
  &:hover {
    background: #f1f1f1;
  }
}

.submitButton {
  height: 42px;
  background: #103255;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  text-transform: none !important;
  display: table-cell;
}

.attachIcon:hover {
  background-color: transparent !important;
}

.css-1v6auu6-MuiTypography-root {
  font-weight: 300 !important;
}

.MuiCircularProgress-indeterminate {
  animation: circular-rotate 1.4s linear infinite;
}

.progress {
  width: 20%;
  padding-left: 10px;
  padding-top: 8px;
  display: table-cell;
  .MuiCircularProgress-svg {
    color: #003258;
  }
}

.commentButton {
  width: 40%;
  padding-right: 4px;
}

.MuiBadge-badge {
  background-color: #003258;
  color: #fff;
}

@keyframes circular-rotate {
  0% {
    transform: rotate(0deg);
    /* Fix IE11 wobbly */
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(360deg);
  }
}

.uploadFiles {
  padding-right: 6px;
  display: inline-flex;
}

.row {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-spacing: 10px;
}

.commentBox {
  height: 58%;
  position: relative;
  overflow-y: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.uploadFileList {
  height: 20px;
  font-size: 12px !important;
  padding: 0 !important;
  padding-left: 4px !important;
  font-weight: 400 !important;
  text-transform: none !important;
  font-family: "Roboto";
  font-style: normal !important;
  line-height: 14px !important;
  color: #103255 !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  cursor: inherit !important;
  &:hover {
    background-color: transparent !important;
    border: 1px solid rgba(0, 0, 0, 0.3) !important;
  }
  .removeFile {
    height: 0.75em;
    color: rgba(0, 0, 0, 0.5);
  }
  .removeFileIcon {
    &:hover {
      background-color: transparent;
    }
  }
}

.fileList {
  max-height: 70px;
  overflow-y: auto;
  min-height: 28px;
}
