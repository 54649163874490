.reviewBox {
  padding: 10px 30px;
  .heading {
    margin-bottom: 20px;
  }
  .values {
    margin-left: 10px;
    margin-bottom: 10px;
  }
  .flex-container {
    display: flex;
    flex-direction: column;
    .flex-row-parent {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      width: 100%;
      margin: 5px 0;
      .flex-row.stepInfo {
        width: 20%;
        .halfStep {
          width: 75px;
          height: 100%;
          border-right: 1px solid #BDBDBD;
        }
      }
      .flex-row.stepInfo-allThree {
        width: 100%;
        height: 100%;
        .halfStep {
          width: 75px;
          border-right: 1px solid #BDBDBD;
        }
      }
      .flex-column {
        display: flex;
        flex-direction: column;
        width: 80%;
      }
      .flex-column-stretch {
        display: flex;
        flex-direction: column;
        width: 20%;
        align-items: stretch;
      }
      .flex-column-values {
        width: 80%;
        padding: 6px 0;
      }
    }
    .flex-row-stepper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .flex-item-icon {
        width: 40px;
      }
    }
  }
}
p.text-stepper {
  color: #103255;
  font-weight: 400;
  font-size: 12px;
}
p.text-emphasis {
  color: #103255;
  font-weight: 500;
  font-size: 14px;
}
p.font-light {
  color: rgba($color: #000000, $alpha: 0.54);
  font-weight: 400;
  font-size: 14px;
}
p.description {
  color: rgba($color: #000000, $alpha: 0.54);
  font-weight: 300;
  font-size: 12px;
}