.reportIssueBox{
    padding: 20px;
    button{
        text-transform: none;
    }
    .ownerFlow{
        .stepCounterEditOwnerAddress {
            margin: 10px -8% 30px;
          }
        .editOwnerText {
            padding-bottom: 16px;
        }
        .editOwnerFooterText{
            font-style: italic;
            opacity: 60%;
            text-align: center;
            padding-top: 16px;
        }
        .editOwnerButtons{
            display: flex;
            justify-content: flex-end;
            margin-top: 50px;
        }
        .lettersDataContainer{
            max-height: 45vh;
            overflow-x: hidden;
            overflow-y: auto;
            .lettersDataBox{
                width: 100%; 
                margin-bottom: 15px;
                .letterInputLabel{
                    padding-left:2%
                }
            }
        }
    }
    
}