
/* General */
.qjson h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 30px;
  color: rgb(255, 82, 64);
}

.qjson p.prepend,
.qjson p.append {

}

.qjson .back-forward {
  margin-top: 20px;
  display: 'flex';
  justify-content: 'flex-end';
}

.qjson .back-forward .back {
  margin-right: 'auto';
  color: #747474;
  height: 50px;
}

.qjson .back-forward .back,
.qjson .back-forward .forward {
  border-color: #ccc;
  border-radius: 25px;
}

.qjson .back-forward .back,
.qjson .back-forward .forward .button {
  padding-left: 30px;
  padding-right: 30px;
}

.qjson .back-forward .forward .button {
  background: linear-gradient(to right, rgb(255, 82, 64), rgb(255, 82, 64));
  border-radius: 25px;
  color: rgb(255,255,255);
  font-weight: bold;
  margin-left: -9px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.qjson .back-forward .forward .enter {
  color: black;
  font-weight: normal;
  text-transform: initial;
  margin-left: 10px;
}

.MuiFormHelperText-contained {
  min-height: 1.45rem;
}

.action-button-nav {
  background: linear-gradient(to right, rgb(255, 82, 64), rgb(255, 82, 64));
  color: #fff !important;
  border-radius: 50px !important;
  border: none !important;
}

/* Radio */

.inputs-radio button {
  background-color: #f1f3f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #333;
  font-size: 130%;
  line-height: 1.6em;
  outline: none;
  padding: 8px;
  text-align: center;
  text-transform: none;
  margin-right: 14px;
  margin-bottom: 14px;
  min-height: 120px;
  width: 98%;
}

.inputs-radio button:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}

/* Check List */

.inputs-check-list h2 {
  color: #1c1c1c;
  margin-bottom: 10px;
  margin-top: 40px;
}

.inputs-check-list label {
  margin-bottom: 20px;
}

.inputs-check-list label .MuiCheckbox-root {
  margin: 0 12px;
}

.inputs-check-list label h3 {
  font-weight: normal;
  margin: 0;
}

.inputs-check-list label p {
  margin: 0;
  color: rgba(0,0,0,0.54);
}

/* Signature */

.inputs-signature canvas {
  border: 1px dashed black;
  height: 300px;
  width: 960px;
}

.list-item {
  margin: 10px 0px 25px 0px;
}

.list-item:hover {
  background-color: #f0f0f0;
}

.error-message {
  font-size: 80% !important;
  line-height: 1em !important;
  padding: 7px;
}

.clear-signature {
  background: linear-gradient(to right, rgb(255, 82, 64), rgb(255, 82, 64));
  color: #fff;
  margin-top: 10px !important;
}

.signature-label {
  color: #0000009c;
  font-size: 18px !important;
  margin-bottom: 10px !important;
}

/* Summary-media */
.download-button {
  background: linear-gradient(to right, #e5163d, #ef6a57);
  color: #ffffff !important;
  border-radius: 50px !important;
  padding: 10px 20px !important;
  font-size: 15px !important;
  font-weight: normal !important;
  font-family: inherit !important;
  letter-spacing: .05em ;
}

.download-button:hover {
  box-shadow: 0px 0px 16px #ef6a57;
}

/* SignIn Token */
.request-code-button {
  background: linear-gradient(to right, #ff5240, #ff5240);
  color: #ffffff !important;
  border-radius: 50px !important;
  padding: 10px 20px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-family: inherit !important;
  letter-spacing: .05em ;
}

.request-code-button-disabled {
  background-color: #0000001f !important;
  color: #00000061 !important;
  border-radius: 50px !important;
  padding: 10px 20px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-family: inherit !important;
  letter-spacing: .05em ;
}

/* Invoice */
.amount-cell {
  text-align: right !important;
  font-size: 110% !important;
  width: 10%;
}

.dollar {
  font-size: 130% !important;
  padding-top: 12px;
  padding-right: 5px;
}

.amount-box {
  display: flex;
  flex-direction: row;
}

.sub-total-label {
  text-align: right !important;
  font-weight: bold !important;
  font-size: 21px !important;
  border-right: 1px solid #e0e0e0;
}

.sub-total-amount {
  text-align: right !important; 
  font-size: 21px !important;
}

.checkbox-cell {
  width: 5%;
  border-right: 1px solid #e0e0e0;
}

.description-cell {
  text-align: left !important;
  font-size: 21px !important;
}

/* Radio-list */
.main-text {
  font-weight: 400 !important;
  letter-spacing: .01em;
}

.address-text {
  margin: 2px 0 5px 0 !important;
  margin-bottom: 5px !important;
  color: rgba(0, 0, 0, 0.54);
}

.search-input {
  margin: 40px 0px 20px 0px;
}

.inputs-radio-list{
  margin: 20px 0px 30px 0px;
}

/* Uploads */
.upload .list-item {
  background: #f8f8f8;
  border: 5px dashed #ddd;
}

.upload .dragover{
  background: #f8f8f8;
  border: 5px dashed blue;
}

.subtext {
  color: #0000008a;
  margin-top: 5px !important;
  font-size: 0.850rem !important;
}

.button-icon {
  background-color: #ff5240 !important;
  color: #fff !important;
  margin: 0px 10px 0px 10px !important;
}

/* Summary */
.custom-primary-text {
  font-size: 16px !important;
}

.custom-secondary-text {
  font-size: 14px !important;
  color: #0000008a;
}

.summary-list-item {
  margin: 8px 0px 15px 0px;
}

/* Payment Method */
.payment-method {
  font-weight: bold;
}

.subtext {
  color: "rgba(0, 0, 0, 0.54)";
  font-size: 18px !important;
}

.secondary-item {
  margin-right: 32px;
}

.secondary-item-text {
  font-weight: 500 !important;
}


