.dashboardCard {
  .MuiCardContent-root {
    padding: 8px 0 8px 16px;
  }
  .MuiGrid-item {
    h5 {
      margin-top: 10px;
      margin-right: 16px;
    }
    ul {
      padding: 0;
    }
  }
  .actions {
    position: absolute;
    right: 8px;
    margin-top: 8px;
    button {
      margin-bottom: 8px;
    }
  }
}
.HTMLCell {
  &:hover {
    position: absolute;
    z-index: 10000;
    background-color: #f5f5f5;
    padding: 5px;
  }
}

.listing-icon-link {
  margin: auto 5px auto 0 !important;
}
.listing-icon {
  width: 20px;
  height: 20px;
}

.listings-icons-box {
  display: flex;
  overflow: hidden;

  &.hovered {
    flex-wrap: wrap;
    overflow-y: inherit;
    width: 100%;
  }
}

.listings-icons-container {
  display: flex;
  overflow: inherit;
  align-items: center;
  position: absolute;
  max-width: inherit;
  &.hovered {
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    z-index: 10000;
  }
}

.listings-show-more {
  position: relative;
  right: 0;
  padding: 10px;
}

.overLay {
  z-index: 1100;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  width: 100%;
  height: calc(100% - 84px);
  background-color: #f0f0f0;;
  opacity: 0.5;
}

.fullScreenOverlay {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  opacity: 0.5;
  pointer-events: auto;
}

.MuiTablePagination-root {
  margin-right: 40px;
}

.selection-options-container {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
}

.selection-section-cta {
  margin-left: 30px;
  cursor: pointer;
  color: #032D4C;
}

.selection-section-text {
  margin: 0px;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-300 {
  font-weight: 300;
}

.banner-container {
  display: flex;
  justify-content: center;
  margin: 0 32px;
}

.dark-row-hover .MuiDataGrid-row:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}