.iconWrapperValue {
    width: 20px;
  }
  
  .hoverClassValue {
    &:hover {
      cursor: pointer;
    }
  }
  
  .infoGreyValue {
    border-radius: 10px;
    &:hover {
      background: #f1f1f1;
    }
  }
  
  .informationTileValue {
    padding: 1% 3%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 40px);
  }
  .iconContainer {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    .flex-row {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        width: 100%;
        margin-bottom: 3px;
        .flex-element {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            width: 40%;
        }
        .flex-element-values {
            width: 45%;
        }
        .flex-chevron {
        width: 40px;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        }
    }
    .flex-row-letters {
        align-items: flex-start;
        .flex-letters-group {
            display: flex;
            flex-direction: column;
            .flex-letter-element {
                margin-bottom: 5px;
            }
        }
    }
