.editableDropDownContainer{
    z-index:32;
    background:#fff;
    max-width: 300px;
    width: 100%;
    margin: auto;
    position: absolute ;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.25);
    border-radius: 6px;
    padding:16px;
    border:1px solid rgba(0,0,0,0.25);
}

.emptyGridCell {
    position: relative;
    display: flex; 
    height: 100%;
    width: 100%;
}

.editableGridCell {
    position: relative;
    display: flex;
}

.nonEditableGridCell {
    padding: 8px; 
}
