@import "../../index.scss";

@mixin fontStyle {
    color: #000000 !important;
    font-family: 'Roboto' !important;
    font-style: normal !important;
} 

.dialogTitle {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    @include fontStyle;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 4px !important;
    
}

.dialogContentMain {
    padding-top: 5% !important;
    .dialogContent {
        @include fontStyle;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        padding-top: 4%;
    }
    
    .dialogContentInfo {
        @include fontStyle;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
    }
}

.dialogActions {
    justify-content: space-between !important;
    padding-left: 4% !important;
    padding-right: 4% !important;
    padding-bottom: 4% !important;

    .continueButton {
    font-family: 'Roboto' !important;
    font-style: normal !important;
    text-transform: none !important;
    padding: 6px 9px !important;
    letter-spacing: 0.46px;
    background: #103255 !important;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    height: 42px;
    line-height: 1.75;
    }

    .discardButton {
    background: #fff !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    @include fontStyle;
    @include unStyledButton;
    }
}