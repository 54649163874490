.viewsGrid{
    width: 30%;
}

.noViewsGrid{
    width: unset !important;
    .viewsList{
        padding-top: 0;
        padding-bottom: 0;
    }
}

.viewsList,.deleteView{
    overflow-y: scroll;
    max-height: 450px;
    .menuItemContainer{
        display: flex;
        width: 100%;
        min-height: 30px;
        .viewItemIcom{
            opacity: 0.75;
            justify-content: flex-start;
            align-items: center;
            margin: 2px 10px 2px 0px;
        }
        p{
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
.addItemIcon{
    margin: 5px 10px 5px 0px;
}
.viewControl{
    padding-left: 16px !important;
    padding-right: 16px !important;
    display: flex;
    .MuiButton-startIcon{
        margin-right:2px
    }
}

.deleteView {
    li{
    background-color:#f2f4f7 !important;
    cursor: default;
    color: #29292b;
    padding:5px;
    &:hover{
        background-color: #f2f4f7 !important;
    }
    };
    button{
        text-transform: unset;
    }
}