.bottomRightBox {
    height: auto;
    max-height: 40px;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    bottom: 23px;
    right: 60px;
    .submitMapButton {
        border-radius: 0;
        background-color: white;
        color: grey;
        &:hover {
          background-color: lightgrey;
        }
        text-align: center;
        align-items: center;
        justify-content: center;
        font-size: 18px;
      }
    .highlightMapButton{
        color: black;
    }
  }
  
