.activityTimelineContainer {
  background-color: #fff;
  padding: 20px 30px 24px 30px;
  margin-bottom: 30px;
  height: fit-content;
  border-radius: 10px;
  .bold {
    font-weight: 700;
  }
  .title {
    margin-bottom: 20px;
  }
  .activityTimeline {
    margin: 10px 20px;
  }
  .MuiDataGrid-root {
    border: none;
    justify-content: left;
    .MuiDataGrid-virtualScroller {
      overflow: hidden;
    }

    .MuiDataGrid-row {
      break-inside: avoid !important;
      page-break-after: avoid !important;
      page-break-before: avoid !important;
    }

    .MuiDataGrid-columnHeader {
      background-color: #fff;
    }
  }
}
